import SearchSection from "../../ui/SearchSection";
import './UserDailyDiary.css'
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { useParams } from "react-router-dom";
import React, { useState, useEffect} from 'react';
import moment from 'moment';
import { get, put } from "../../api/apiCalls";
import "./Diary.css";
import DateSelector from "../../ui/DateSelector";
import TimezoneSelector from "../../ui/TimezoneSelector";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import { Box, Modal, Typography } from "@mui/material";
import { ReactSession } from 'react-client-session'
import Select from 'react-select'
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function UserDailyDiary(props) {
  const params = useParams();
  let [date, setdate] = useState(0);
  let [timeOffset,setTimeOffset] = useState(0);
  let [timecards, setTimecards] = useState([]);
  let diaryRoot = document.getElementById('diaryRoot');
  let [summary, setSummary] = useState([]);
  let [loading,setLoading] = useState(false);
  let [selectedDiaryDate,setSelectedDiaryDate] = useState(moment().format("YYYY-MM-DD"));
  let [projects, setProjects] = useState([])
  let [project, setProject] = useState()
  let[loadingImages, setLoadingImages]= useState(true);
  const [loggedUser, setLoggedUser] = useState()
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const setDiaryDate = (date) => {
    setdate(date);
    setSelectedDiaryDate(date);
    selectedDiaryDate = date;
    getSummaryDetails();
  };

  const getSummaryDetails = async (offset) => {
		setLoading(true);
		const keys = {
		  date: moment(selectedDiaryDate).format("YYYY-MM-DD"),
		  id: params.employeeId,
      offset
		};
	  
		let summary = await fetchSummaryData(keys);
		if (summary.length <= 0) {
      setLoading(false);
      setLoadingImages(false)
		} else {
      setSummary(summary);
      setLoading(false);
      setLoadingImages(false)
		}
	};
	const fetchSummaryData = async (keys) => {    
		try {
		  const response = await get("TrackingData/DiaryUserTrackingData", keys);
		  return response;
		} catch (error) {
		  console.error("Error fetching summary data:", error);
		  return undefined;
		}
	};
  
  const handleUpdateCards = async() => {
    setOpen(true)
    getUserProjects()
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for(let i = 0 ; i < checked.length ; i++) {
        num.push(parseInt(checked[i].id));     
        checked[i].checked = true;
    }
    setTimecards(num)

  }

  let changeTimeOffset = (Offset) => {
    timeOffset = Offset
    getSummaryDetails(timeOffset);
  }
  
  const getUserProjects = async() => {
    let key = {
      UserId: loggedUser.id
    }
    let list = []
    let response = await get("Projects/GetProjectsForAgent?UserId="+key.UserId)
    response.forEach(e => {
      let model = {
        value:'',
        label:''
      }
      model.value = e.id
      model.label = e.name
      list.push(model)
    });
    setProjects(list)
  }
      
  const handleSelectChange = (project) => {
    setProject(project)
  }

  const uncheckTimeCards = () => {
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for(let i = 0 ; i < checked.length ; i++) {    
        checked[i].checked = false;
    }
    setTimecards([])
  }

  const handleSubmitUpdateTimeCard = async() => {
    setOpen(false)
    setProject()
    uncheckTimeCards()
    let keys = {
      cardsId: timecards,
      projectId: project.value,
      projectName: project.label
    }
    let response = await put("TrackingDataAudit/UpdateTimeCards", keys)
    alert(response)
    getSummaryDetails();
  }   
  useEffect(() => {
    let user = ReactSession.get("User")
    setLoggedUser(user)
    if(summary.length <=0)
      getSummaryDetails();
    //setDiaryDate(moment().format("YYYY-MM-DD"));
    // componentWillMount events
  },[summary]);

  if(summary.length <= 0){
    return(<div className="diary">
      <SearchSection />
      <div>
      <ManagerSubHeaderLinks id={params.employeeId}/>
        <div className="diaryMain">
          <div className="filters">
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} className='cc' />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} value={'Etc/GMT'} />
            {loading && <LoadingSpinner />} 
          </div>
          {!loading && <p className="no-data-msg">No timecards for the selected day</p>}
        </div>
      </div>
    </div>
                

    );

    
  }
 

  return (
    <div className="diary">
      <SearchSection />
      <div>
        <ManagerSubHeaderLinks id={params.employeeId}/>

        <div className="diaryMain">
          <div className="filters">
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")}  updateTimers={setDiaryDate} />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} value="Etc/GMT" />
            {loading && <LoadingSpinner />}
          </div>
          <button
            className="timecards-action-btns"
            id="update-cards"
            onClick={handleUpdateCards}
          >
            Update Timecard
          </button>
          <DiaryTimeLine
          data = {summary}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update time card
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select the project the timecard should be moved to.
          </Typography>
          <Box className="update-timecards-box">
            <Select
                className="basic-single timecards-selection"
                classNamePrefix="select"
                placeholder="Select a project"
                name="color"
                value={project}
                defaultValue={project}
                onChange={handleSelectChange}
                options={projects}
            />
            <button
              className="confirm-timecards-update-btn"
              onClick={handleSubmitUpdateTimeCard}
              disabled={project === null || project === undefined || timecards.length <= 0}
            >
              Update
            </button>
            {timecards.length <= 0 &&(
              <span className="update-timecards-info-text">* You need to select at least one time card before update</span>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default UserDailyDiary;
