import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment';

import { CSVLink } from "react-csv";
import "react-data-table-component-extensions/dist/index.css";

import { BiDownArrow } from "react-icons/bi";
import "./DataTableBase.css";

const sortIcon = <BiDownArrow />;

const customStyles = {
  rows: {
    style: {
      height: "68px",
      fontSize: "15px"
    },
    stripedStyle: {
      backgroundColor: "#E9FBF3",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#7FC0A2",
      height: "90px",
      borderRadius: "40px 40px 0 0",
      textAlign: "center",
    },
  },
  headCells: {
    style: {
      borderRightColor: "white",
      borderRightStyle: "solid",
      fontSize: "19px",
      fontWeight: 600,
    },
  },
  cells: {
    style: {
      paddingLeft: "15px",
      paddingTop: "10px",
    },
  },
};

const {
  Parser,
  transforms: { unwind }
} = require("json2csv");
function DataTableBase(props) {
  let initialvalue = "";
  let paths = [];
  function collectKeys(inputObject, outputKeys) {
    if (Array.isArray(inputObject)) {
      collectKeys(inputObject[0], outputKeys);
    } else if (typeof inputObject == "object") {
      Object.keys(inputObject).forEach(function (key) {
        if (typeof inputObject[key] == "object") {
          initialvalue
            ? (initialvalue = initialvalue + "." + key)
            : (initialvalue = key);
          paths.push(initialvalue);
          
          collectKeys(inputObject[key], outputKeys);
        } else {
          if (initialvalue) {
            outputKeys.push(initialvalue + "." + key);
          } else {
            outputKeys.push(key);
          }
        }
      });
    }
    return outputKeys;
  }

  const tableData = {
    ...props,
  };

  let dataforCSV = props.fullData ? props.fullData : props.data;
  
  const fields = collectKeys(dataforCSV, []);
  const transforms = [unwind({ paths: paths })];
  const unwindData = new Parser({ fields, transforms, quote: ''});
  const csvData = unwindData.parse(dataforCSV);

  return (
    <>
      <div className="project">
        {(props.showDownloadButton == null || props.showDownloadButton) && (
          <CSVLink
          data={csvData}
          className="export-button"
          filename={props.fileName?(props.fileName+moment().utc().format("_YYYYMMDD:HHmmSS [GMT]")):"SiriusExport.csv"}
          onMouseDown={props.downloadCall}
        >
          Download
          
        </CSVLink>
        )}
        
      </div>
      <DataTableExtensions {...tableData} export={false} print={false}>
        <DataTable
          pagination
          sortIcon={sortIcon}
          defaultSortFieldId={props.defaultSortFieldId}
          defaultSortAsc={props.defaultSortAsc}
          striped
          dense
          fixedHeader
          {...props}
          customStyles={customStyles}
          expandableRows={props.expandableRows}
          expandableRowsComponent={props.expandableRowsComponent}
          expandableRowsComponentProps={props.expandableRowsComponentProps}
          noTableHead={props.noTableHead}
        />
      </DataTableExtensions>
    </>
  );
}

export default DataTableBase;
