import { GrClose } from 'react-icons/gr';
import { useMemo, useState } from 'react';
import TextInput from '../../ui/TextInput.js';
import { ReactSession } from 'react-client-session';

import './ProjectsModal.css';
import { get } from '../../api/apiCalls.js';

function AddProjectsModal(props) {
  const EmployeeNames = [];

  let [projectName,setProjectName] = useState('')
  let [ContactName,setContactName] = useState('')
  let [Hours,setHours] = useState('')
  let [managers, setManagers] = useState([]);
  let [manager, setManager] = useState('');
  let [managerId, setManagerId] = useState(0);
  const [sharedService, setSharedService] = useState(false);
  let user = ReactSession.get("User");

  
  function setShared() {
    setSharedService(!sharedService);
  }

  const getManagers = async() => {
    let response = await get("Users/GetManagers")
    setManagers(response)

  }

  function removeMember(data) {
    // add remove member function here
    //console.log(data);
  }

  const handleManagerChange = (event) => {
    if(event.target.value !='default'){
      let result = event.target.value.split(' - ')
      setManagerId(result[0])
      setManager(result[1])
    }else{
      setManager('')
    }

  }
  

  useMemo(() => {
    getManagers();
  }, [])


  const columns = [
    {
      id: 'agentname',
      name: 'Name',
      selector: (row) => row.agentname,
    },
    {
      id: 'removeagent',
      name: 'Remove Agent',
      selector: (row) => row.removeagent,
      cell: (row) => {
        return (
          <button className='remove-member' onClick={() => removeMember(row)}>
            Delete Member
          </button>
        );
      },
    },
  ];


  return (
    <div className='form-modal project-modal' id='project-form-modal'>
      <div className='close-modal' onClick={props.onCancel}>
        <GrClose />
      </div>
      <div id='add-project-form'>
        <div className='project-details-card'>
          <div className='project-headers'>Add Project Details</div>
          <div className='project-card'>
            <TextInput
              type='text'
              required='required'
              text='Project Name *'
              id='project-name'
              value=''
              onChangeText={setProjectName}
            />
            <TextInput
              type='text'
              required='required'
              text='Contact Name *'
              id='project-contact'
              value=''
              onChangeText={setContactName}
            />
            <TextInput
              type='number'
              required='required'
              id='project-hours'
              value=''
              text='Hour Cap Per Agent *'
              onChangeText={setHours}
            />
            <div className='manager-select-box'>
              <label htmlFor='manager-selector'>Project's Manager *</label>
              <select id='manager-selector' required className='manager-selector' onChange={handleManagerChange}>
                <option value="default"> -- Manager -- </option>
                {managers.map((manager) => (
                  <option key={manager.id} value={`${manager.id} - ${manager.firstName} ${manager.lastName}`}>
                    {manager.firstName} {manager.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className='add-project-input-with-label'>
              <label htmlFor='is-shared-project'>
                Shared Service
              </label>
              <input
                type='checkbox'
                id='is-shared-project'
                checked={sharedService}
                onChange={setShared}
              />
            </div>
          </div>
        </div>
        <div className='btn-and-alert'>
          <button
          id='add-project-btn'
          disabled={!projectName || !Hours || !ContactName || !manager}
          onClick={() => props.addProject({Name:projectName, ContactName:ContactName, sharedService:sharedService, managerEmail:user.username, Hours:Hours, manager:manager, managerId:managerId})} className='project-info-button'>
            Create Project
          </button>
          {!projectName || !Hours || !ContactName || !manager ? (
            <small>The fields with (*) are mandatory</small>
          ) : null }
        </div>
      </div>
    </div>
  );
}

export default AddProjectsModal;
